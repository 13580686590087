import VectorSource from "ol/source/Vector";
import { defaults as interactionDefaults } from "ol/interaction";

import BaseMapManager from "./base";
import { panelsStyle } from "../styles/zone-panels";

export default class ProjectReportMapManager extends BaseMapManager {
  add() {
    this.buildPanelsVectorSource();
    this.buildSegmentsVectorSource();
    this.buildRailsVectorSource();
    this.buildContoursVectorSource();

    this.map = this.buildMap({
      layers: [this.tileLayer, this.panelsVectorLayer, this.railsVectorLayer, this.contoursVectorLayer],
      interactions: interactionDefaults({ doubleClickZoom: false, mouseWheelZoom: false }),
    });

    this.addBuildingImagePlacementLayers();
    this.renderContourFeatures();
  }

  buildPanelsVectorSource() {
    this.panelsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.featuresForAllPanels(),
    });
  }

  panelsStyle = (feature) => {
    feature.set("displayingInProjectReport", true);
    return panelsStyle(feature, this.map, this.controller.project.is716);
  };
}
