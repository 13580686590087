import { Controller } from "@hotwired/stimulus";
import * as animate from "../helpers/animate";

export default class extends Controller {
  static targets = ["showOnSubmit"];

  submit(_event) {
    this.showOnSubmitTargets.forEach((element) => {
      animate.show(element);
    });

    this.element.requestSubmit();
  }
}
