import MapBaseController from "./map_base_controller";

import { applyOpenLayersUpdatesFromModels } from "../api_open_layers_helpers";
import { applyDomUpdatesFromResponse } from "../../../helpers/api-dom-helpers";
import { removeMatchingKeys } from "../../../helpers/local-store";
import * as toolbarBtn from "../../../da/layout-editor/helpers/toolbar-btns";
import {
  EDITOR_MODE_DRAW,
  EDITOR_MODE_MEASURE,
  EDITOR_MODE_SELECT,
} from "../../../da/layout-editor/helpers/toolbar-constants";

export default class MapRoofPlanesAndSectionsController extends MapBaseController {
  connect() {
    this.showBoundingBoxes = this.element.dataset.showBoundingBoxes === "true";

    super.connect();

    if (this.project.roofPlanes.length === 0) {
      removeMatchingKeys((k) => k.match(/^le--selectGroup--bx.*/));
    }

    this.mapManager.buildSetbacksVectorSource();
    this.mapSpecificConnect();
    this.setupMapInteractionManagers();

    this.initializeEditorMode();

    this.populateStatusbar();

    document.addEventListener("keydown", this.handleKeydown);
  }

  mapSpecificConnect() {
    // override in sub class
  }

  setupMapInteractionManagers() {
    // override in sub class
  }

  initializeEditorMode() {
    switch (this.editorMode) {
      case EDITOR_MODE_DRAW:
        this.startDrawMode();
        break;
      case EDITOR_MODE_MEASURE:
        this.startMeasureMode();
        break;
      case EDITOR_MODE_SELECT:
        this.startSelectMode();
        break;
      default:
        this.startDrawMode();
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    if (event.key === "Backspace") {
      if (event.target.tagName !== "INPUT") {
        event.preventDefault();
        this.removeSelectedFeatures(event);
      }
    } else if (event.key === "Escape") {
      this.drawInteractionManager.finish();
      this.measureInteractionManager.finish();
    }
  };

  startDrawMode(_event) {
    this.drawInteractionManager.add();
    this.selectInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.measureInteractionManager.remove();
    if (this.page === "roofPlanes") {
      this.removeBuildingImagePlacementTransformInteractions();
    }
  }

  startSelectMode(_event) {
    this.drawInteractionManager.remove();
    this.selectInteractionManager.add();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.measureInteractionManager.remove();
    this.rulerInteractionManager.remove();
    if (this.page === "roofPlanes") {
      this.addBuildingImagePlacementTransformInteractions();
    }
  }

  startMeasureMode(_event) {
    this.drawInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.selectInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.measureInteractionManager.add();
    this.rulerInteractionManager.remove();
    if (this.page === "roofPlanes") {
      this.removeBuildingImagePlacementTransformInteractions();
    }
  }

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(_event, onSuccess = () => {}) {
    this.preSaveForPage();
    toolbarBtn.showSpinner(this.saveBtnTarget);
    this.project.save({
      path: this.savePath,
      onSuccess: (json) => {
        if (this.undoQueue) {
          this.clearUndoQueue();
          this.addSnapshotToUndoQueue(false);
        }
        if (json) applyDomUpdatesFromResponse(json);
        applyOpenLayersUpdatesFromModels(this.project, this.mapModelSynchronizer, this.mapManager);
        this.markClean();
        onSuccess();
        setTimeout(() => {
          toolbarBtn.hideSpinner(this.saveBtnTarget);
        }, 500);
      },
      onValidationError: this.onValidationError,
      onServerError: this.onServerError,
      includeProjectSite: true,
      includeUploadedBuildingImages: true,
    });
  }

  preSaveForPage() {
    // override in child
  }
}
