import { types } from "mobx-state-tree";
import { v4 as uuid } from "uuid";

import DaProjectModel from "../../da/models/project-model";
import RoofPlaneModel, { roofPlanePersistenceData } from "./roof-plane-model";
import ObstructionModel, { obstructionPersistenceData } from "./obstruction-model";
import LatLngModel from "../../da/models/lat-lng-model";
import { projectSitePersistenceData } from "../../da/models/project-site-model";
import { logger } from "../../helpers/app";
import * as Api from "../../helpers/api-mobx";
import { uploadedBuildingImagePersistenceData } from "../../da/models/uploaded-building-image-model";

const ProjectModelBase = types
  .model("ProjectModel", {
    roofPlanes: types.array(RoofPlaneModel),
    deletedRoofPlanes: types.array(RoofPlaneModel),
    obstructions: types.array(ObstructionModel),
    azimuth: types.number,
    northExtension: types.number,
    southExtension: types.number,
    focusedRoofSection: types.maybe(types.integer),
    globalOrigin: LatLngModel,
  })
  .views((self) => ({
    get shouldLengthCorrectEdges() {
      return false;
    },
    get panelsPresent() {
      return self.roofPlanes.reduce((count, roofPlane) => {
        return count + roofPlane.panelsPresent;
      }, 0);
    },
    get wattage() {
      return self.panelsPresent * self.panelWattage;
    },
    getNextSequentialObstructionIdentifier() {
      const numActiveObstructions = self.obstructions.filter((o) => !o.deleted).length;
      return `${numActiveObstructions + 1}`;
    },
    get notDeletedObstructions() {
      return self.obstructions.filter((obstruction) => obstruction.notDeleted);
    },
    getObstruction(uuid) {
      return self.obstructions.find((obstruction) => obstruction.uuid === uuid);
    },
    get anyObstructionNeedsSave() {
      return self.obstructions.some((obstruction) => obstruction.needsSave);
    },
    get needsSave() {
      return (
        self.dirty ||
        self.projectSite.needsSave ||
        self.hasDetailAndNeedsSave ||
        self.anyObstructionNeedsSave ||
        self.anyRoofPlaneNeedsSave ||
        self.anyUploadedBuildingImageNeedsSave
      );
    },
  }))
  .actions((self) => ({
    setAzimuth(newAzimuth) {
      if (self.azimuth === newAzimuth) return;

      self.markDirty();
      self.azimuth = newAzimuth;
    },
    addRoofPlane(params) {
      const defaultParams = {
        uuid: uuid(),
        identifier: self.getNextSequentialRoofPlaneIdentifier(),
      };
      const mergedParams = { ...defaultParams, ...params };
      const roofPlane = RoofPlaneModel.create(mergedParams);
      self.roofPlanes.push(roofPlane);

      return roofPlane;
    },
    addObstruction() {
      const params = {
        uuid: uuid(),
        identifier: self.getNextSequentialObstructionIdentifier(),
      };
      const obstruction = ObstructionModel.create(params);
      self.obstructions.push(obstruction);

      return obstruction;
    },
    destroyObstruction(obstruction) {
      if (obstruction.id) {
        obstruction.flagDeleted();
      } else {
        self.obstructions.remove(obstruction);
      }
    },
    resequenceObstructionsIdentifiers() {
      const notDeletedObstructions = self.obstructions
        .slice()
        .sort((a, b) => a.identifier.localeCompare(b.identifier))
        .filter((rp) => !rp.deleted);
      notDeletedObstructions.forEach((rp, i) => {
        rp.setIdentifier(`${i + 1}`);
      });
    },
    applyUpdatesReturnedBySave(json) {
      const roofPlanesJson = json?.["model"]?.["roofPlanes"];

      self.roofPlanes.forEach((rp) => {
        const roofPlaneJson = roofPlanesJson?.find((rpJson) => rpJson["uuid"] === rp.uuid);
        rp.applyUpdatesReturnedBySave(roofPlaneJson);
      });
    },
    save({
      path,
      onSuccess = () => {},
      onValidationError = () => {},
      onServerError = () => {},
      includeProjectSite = true,
      includeUploadedBuildingImages = true,
    }) {
      if (self.saving) {
        logger("Already saving. Ignoring request.");
        return;
      }
      self.setSaving();

      const payload = persistenceData(self, includeProjectSite, includeUploadedBuildingImages);
      const onSuccessWithModelUpdates = (json) => {
        self.applyUpdatesReturnedBySave(json);
        onSuccess(json);
      };
      return Api.put({
        model: self,
        path,
        body: payload,
        onSuccess: onSuccessWithModelUpdates,
        onValidationError,
        onServerError,
      });
    },
    processResponse() {},
  }));

function persistenceData(project, includeProjectSite, includeUploadedBuildingImages) {
  logger(`Persisting Project #${project.id}`);

  const projectSiteAttributes = includeProjectSite ? projectSitePersistenceData(project.projectSite) : undefined;

  const detailAttributes = project.dirty ? { id: project.detailId, azimuth: project.azimuth } : undefined;

  const data = {
    detail_attributes: detailAttributes,
    project_site_attributes: projectSiteAttributes,
    roof_planes_attributes: project.roofPlanes.map((roofPlane) => roofPlanePersistenceData(roofPlane)),
    obstructions_attributes: project.obstructions.map((obstruction) => obstructionPersistenceData(obstruction)),
  };

  if (includeUploadedBuildingImages) {
    data.uploaded_building_images_attributes = project.uploadedBuildingImages.map((ubi) =>
      uploadedBuildingImagePersistenceData(ubi),
    );
  }

  return { bx_project: data };
}

const ProjectModel = types.compose(DaProjectModel, ProjectModelBase);
export default ProjectModel;
