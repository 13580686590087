import { Controller } from "@hotwired/stimulus";
import { destroy } from "@rails/request.js";

import { hideDialog } from "../../../controllers/components/ir_dialog/helper";

export default class extends Controller {
  static values = {
    deletePath: String,
    imagePath: String,
    id: Number,
    width: Number,
    height: Number,
    dialogIdentifier: String,
  };

  place(event) {
    event.preventDefault();

    this.mapController.createBuildingImagePlacement({
      imagePath: this.imagePathValue,
      uploadedBuildingImageId: this.idValue,
      width: this.widthValue,
      height: this.heightValue,
    });

    hideDialog(this.dialog);
  }

  // This is better than using an outlet because outlets require a definition tied
  // to the controller name. We want to use this with both the PR and BX trace roof
  // planes map controllers. This approach allows us to be more generic and use
  // this controller in both contexts.
  get mapController() {
    return document.querySelector("[data-identifier='mapContainer']").mapController;
  }

  get dialog() {
    return document.querySelector(`[data-dialog-identifier="${this.dialogIdentifierValue}"]`);
  }

  delete(event) {
    event.preventDefault();
    event.stopPropagation();

    const deleteLink = event.currentTarget;
    deleteLink.disabled = true;
    this.element.classList.add("ol-map__building-images__item--deleting");

    destroy(this.deletePathValue, { responseKind: "turbo-stream" }).then(() => {
      this.mapController.removeUploadedBuildingImage(this.idValue);
    });
  }
}
