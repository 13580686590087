import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = ["showOnSubmit", "button", "fileField", "fileDisplay", "clearFileLink"];

  defaultFileDisplayText = "No file chosen";

  static values = {
    autoSubmit: { type: Boolean, default: false },
  };

  chooseFile(event) {
    event.preventDefault();

    this.fileFieldTarget.click();
  }

  fileChosen(_event) {
    if (this.autoSubmitValue) {
      this.submit();
    } else {
      this.showFileName();
    }
  }

  showFileName() {
    const file = this.fileFieldTarget.files[0];
    const maxLength = 40;
    const fileName = file ? file.name : this.defaultFileDisplayText;
    if (file && fileName.length > maxLength) {
      const extension = fileName.split(".").pop();
      const truncated = fileName.slice(0, maxLength - extension.length - 6) + "... " + "." + extension;
      this.fileDisplayTarget.textContent = truncated;
    } else {
      this.fileDisplayTarget.textContent = fileName;
    }

    this.fileDisplayTarget.setAttribute("title", fileName);

    this.clearFileLinkTarget.classList.remove("d-none");
  }

  submit() {
    this.showOnSubmitTargets.forEach((element) => {
      animate.show(element);
    });

    this.element.closest("form").requestSubmit();
  }

  clearFile(event) {
    event.preventDefault();

    this.fileFieldTarget.value = null;
    this.fileDisplayTarget.textContent = this.defaultFileDisplayText;
    this.clearFileLinkTarget.classList.add("d-none");
  }
}
