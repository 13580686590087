import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["asce", "category"];
  static values = {
    asceToRiskCategories: Object,
  };

  changeAsce() {
    const asce = this.asceTarget.value;
    const allowedRiskCategories = this.asceToRiskCategoriesValue[asce];

    let firstGroupShown = null;
    let lastGroupShown = null;

    this.categoryTargets.forEach((group) => {
      const radioButton = group.querySelector('input[type="radio"]');
      const value = radioButton.value;

      // explicitly remove any forced rounding of buttons
      group.classList.remove("ir-form__radio-group-btns__btn--rounded-right");
      group.classList.remove("ir-form__radio-group-btns__btn--rounded-left");

      if (allowedRiskCategories.includes(value)) {
        group.classList.remove("d-none");
        if (firstGroupShown === null) {
          firstGroupShown = group;
          group.classList.add("ir-form__radio-group-btns__btn--rounded-left");
        }
        lastGroupShown = group;
      } else {
        radioButton.checked = false;
        group.classList.add("d-none");
      }
    });

    if (lastGroupShown !== null) {
      lastGroupShown.classList.add("ir-form__radio-group-btns__btn--rounded-right");
    }
  }
}
