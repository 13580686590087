import MapBaseController from "./map_base_controller";
import ViewOnlyMapManager from "../../../../pr/map/map-managers/view-only";
import BaseMapModelSynchronizer from "../../../../pr/map/map-model-synchronizers/base";

export default class MapViewOnlyController extends MapBaseController {
  static targets = ["map", "compass", "saveBtn"];

  connect() {
    this.viewOnlyMap = true;
    this.renderSegmentsWithZones = true;

    super.connect();

    this.pageRoofPlanes = this.project.roofPlanes;

    this.mapModelSynchronizer = new BaseMapModelSynchronizer(this);

    this.mapManager = new ViewOnlyMapManager(this);
    this.mapManager.add();

    this.mapManager.checkLegality();

    this.element.controller = this;
  }

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(_event, onSuccess = () => {}) {
    this.modelSave({
      model: this.project.projectSite,
      onSuccess,
      includeProjectSite: true,
      includeUploadedBuildingImages: false,
    });
  }

  zoomOut(event) {
    super.zoomOut(event);
    this.updateProjectSiteValuesForOtherMaps(this.attachmentsMapController);
  }

  zoomIn(event) {
    super.zoomIn(event);
    this.updateProjectSiteValuesForOtherMaps(this.attachmentsMapController);
  }

  rotateCounterClockwise(event) {
    super.rotateCounterClockwise(event);
    this.updateProjectSiteValuesForOtherMaps(this.attachmentsMapController);
  }

  mapMoveUpdateOtherMaps() {
    this.updateProjectSiteValuesForOtherMaps(this.attachmentsMapController);
  }

  get attachmentsMapController() {
    const otherMapElem = document.querySelector("[data-controller='pr--image-based--attachments-editor']");
    if (!otherMapElem) return;

    return otherMapElem.controller;
  }
}
