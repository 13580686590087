import MapBaseController from "./map_base_controller";

import MapModelSynchronizer from "../../../../pr/map/map-model-synchronizers/arrays";
import MapManager from "../../../../pr/map/map-managers/arrays";
import MeasureInteractionManager from "../../../../da/map/interaction-managers/measure/base";
import SelectInteractionManager from "../../../../pr/map/interaction-managers/select/arrays";
import SnapInteractionManager from "../../../../pr/map/interaction-managers/snap/arrays";
import ModifyInteractionManager from "../../../../pr/map/interaction-managers/modify/arrays";
import TranslateInteractionManager from "../../../../pr/map/interaction-managers/translate/arrays";
import DrawInteractionManager from "../../../../pr/map/interaction-managers/draw/arrays";
import { radiansToDegrees } from "../../../../helpers/geometry";

import {
  EDITOR_MODE_DRAW,
  EDITOR_MODE_SELECT,
  EDITOR_MODE_MEASURE,
  PANEL_ORIENTATION_PORTRAIT,
  PANEL_ORIENTATION_LANDSCAPE,
  SELECT_TYPE_PANELS,
  SELECT_TYPE_SEGMENTS,
  SELECT_TYPE_MEASURES,
  EDITOR_MODE_PAN_MAP,
  SELECT_TYPE_THERMAL_EXPANSIONS,
} from "../../../../da/layout-editor/helpers/toolbar-constants";
import * as toolbarSelectGroup from "../../../../da/layout-editor/helpers/toolbar-select-group";
import * as toolbarBtn from "../../../../da/layout-editor/helpers/toolbar-btns";
import { reRenderSegment } from "../../../../pr/map/modification-helpers/segments/helpers";
import { applyDomUpdatesFromResponse } from "../../../../helpers/api-dom-helpers";
import SegmentsContourLegalityAdjoinmentsRenderer from "../../../../pr/map/modification-helpers/segments/proximity-alignments/contour-legality-adjoinments-renderer";
import SegmentsAdjoinmentsRenderer from "../../../../pr/map/modification-helpers/segments/proximity-alignments/adjoinments-renderer";
import {
  ADJOINMENT_TYPE_CONTOUR_LEGALITY,
  ADJOINMENT_TYPE_REGULAR,
} from "../../../../pr/map/modification-helpers/segments/adjoining-aligner";
import SegmentsContourCodesClearer from "../../../../pr/map/modification-helpers/segments/contour/codes-clearer";

export default class MapArraysController extends MapBaseController {
  static targets = [
    "map",
    "compass",
    "statusItemZoom",
    "statusItemRotation",
    "statusItemSelections",
    "statusItemPanelsCount",
    "orientationMenuBtn",
    "portraitBtn",
    "landscapeBtn",
    "editorModeMenu",
    "selectTypeMenuBtn",
    "selectPanelBtn",
    "selectSegmentBtn",
    "selectMeasureBtn",
    "selectThermalExpansionBtn",
    "selectTypeMenu",
    "nudgeArrowsMenuBtn",
    "nudgeUpBtn",
    "nudgeRightBtn",
    "nudgeDownBtn",
    "nudgeLeftBtn",
    "arrayNudgingMenuBackground",
    "alignMenuBtn",
    "alignTopBtn",
    "alignBottomBtn",
    "alignLeftBtn",
    "alignRightBtn",
    "addRowsAndColumnsMenuBtn",
    "addRowOnTopBtn",
    "addRowOnBottomBtn",
    "addColumnOnLeftBtn",
    "addColumnOnRightBtn",
    "addThermalExpansionGapMenuBtn",
    "addThermalExpansionGapOnLeftBtn",
    "addThermalExpansionGapOnRightBtn",
    "adjoiningAlignMenuBtn",
    "adjoiningAlignVerticalBtn",
    "adjoiningAlignHorizontalBtn",
    "mergeMenuBtn",
    "mergeVerticalBtn",
    "mergeHorizontalBtn",
    "undoBtn",
    "redoBtn",
  ];

  connect() {
    super.connect();

    this.btnGroups = {
      select: [
        this.selectTypeMenuBtnTarget,
        this.selectPanelBtnTarget,
        this.selectSegmentBtnTarget,
        this.selectMeasureBtnTarget,
        this.nudgeArrowsMenuBtnTarget,
        this.nudgeUpBtnTarget,
        this.nudgeRightBtnTarget,
        this.nudgeDownBtnTarget,
        this.nudgeLeftBtnTarget,
        this.arrayNudgingMenuBackgroundTarget,
      ],
      selectSegments: [
        this.alignMenuBtnTarget,
        this.alignTopBtnTarget,
        this.alignBottomBtnTarget,
        this.alignLeftBtnTarget,
        this.alignRightBtnTarget,
        this.addRowsAndColumnsMenuBtnTarget,
        this.addRowOnTopBtnTarget,
        this.addRowOnBottomBtnTarget,
        this.addColumnOnLeftBtnTarget,
        this.addColumnOnRightBtnTarget,
        this.adjoiningAlignMenuBtnTarget,
        this.adjoiningAlignHorizontalBtnTarget,
        this.adjoiningAlignVerticalBtnTarget,
        this.mergeMenuBtnTarget,
        this.mergeHorizontalBtnTarget,
        this.mergeVerticalBtnTarget,
      ],
      draw: [this.orientationMenuBtnTarget, this.portraitBtnTarget, this.landscapeBtnTarget],
    };

    this.focusedRoofPlaneUuid = this.element.dataset.focusedRoofPlaneUuid;
    this.focusedRoofPlane = this.project.getRoofPlane(this.focusedRoofPlaneUuid);
    this.pageRoofPlanes = [this.focusedRoofPlane];

    this.slopeAdjustedMeasures = true;
    this.renderSegmentsWithZones = false;
    this.allowDisablingThermalExpansionBtns = true;

    this.editorMode = toolbarSelectGroup.getState("prArraysEditorMode", EDITOR_MODE_DRAW);
    this.selectType = toolbarSelectGroup.getState("prSelectType", SELECT_TYPE_SEGMENTS);
    this.panelOrientation = toolbarSelectGroup.getState("prPanelOrientation", PANEL_ORIENTATION_PORTRAIT);

    this.mapSpecificConnect();
    this.setupMapInteractionManagers();
    this.initializeEditorMode();

    this.populateStatusbar();

    setTimeout(() => {
      this.alignViewToFeature(this.mapModelSynchronizer.getFeatureForRoofPlane(this.focusedRoofPlane));
      this.mapManager.rotateTo(this.focusedRoofPlane.eaveRotationAngleRadians);
    }, 200);

    this.mapManager.checkLegality();

    setTimeout(() => {
      this.enableDisableThermalExpansionButton();
    }, 500); // Give the legality checker a chance to run

    setTimeout(() => {
      if (this.focusedRoofPlane.stale) {
        SegmentsContourLegalityAdjoinmentsRenderer.render(this);
        SegmentsAdjoinmentsRenderer.render(this);
        this.markDirty({ force: true });
      }

      this.addSnapshotToUndoQueue(false);
    }, 1000);

    document.addEventListener("keydown", this.handleKeydown);
  }

  undoRedoApplySnapshot(snapshot) {
    if (!snapshot) return;

    super.undoRedoApplySnapshot(snapshot);

    this.setRafterOffsetAfterUndoRedo();
  }

  initializeEditorMode() {
    switch (this.editorMode) {
      case EDITOR_MODE_DRAW:
        this.startDrawMode();
        break;
      case EDITOR_MODE_MEASURE:
        this.startMeasureMode();
        break;
      case EDITOR_MODE_SELECT:
        this.startSelectMode();
        break;
      case EDITOR_MODE_PAN_MAP:
        this.startPanMapMode();
        break;
      default:
        this.startDrawMode();
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    if (event.key === "Backspace") {
      if (event.target.tagName !== "INPUT") {
        event.preventDefault();
        this.selectInteractionManager.removeSelectedFeatures(event);
      }
    }

    if (event.key === "Escape") {
      if (this.drawInteractionManager.isActive) {
        this.drawInteractionManager.cancelDragInteraction();
      }

      this.measureInteractionManager.finish();
    }

    if (event.key === "ArrowUp") this.nudge(event, "up");
    if (event.key === "ArrowRight") this.nudge(event, "right");
    if (event.key === "ArrowDown") this.nudge(event, "down");
    if (event.key === "ArrowLeft") this.nudge(event, "left");
  };

  setRafterOffsetAfterUndoRedo() {
    const rafterOffsetFormController = document.querySelector(
      `[data-controller="pr--image-based--shared--rafter-offset-form"]`,
    ).controller;
    rafterOffsetFormController.setInputField(this.focusedRoofPlane.rafterOffset);
  }

  mapSpecificConnect() {
    this.mapModelSynchronizer = new MapModelSynchronizer(this);
    this.mapManager = new MapManager(this);
    this.mapManager.add();
  }

  setupMapInteractionManagers() {
    this.selectInteractionManager = new SelectInteractionManager(this, this.selectType);

    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();

    this.modifyInteractionManager = new ModifyInteractionManager(this);
    this.translateInteractionManager = new TranslateInteractionManager(this);

    this.measureInteractionManager = new MeasureInteractionManager(this);

    this.drawInteractionManager = new DrawInteractionManager(this);
  }

  populateStatusbar() {
    // When a value like rotation updates, we need to wait for OL to finish doing its thing
    setTimeout(() => {
      this.statusItemZoomTarget.innerText = this.mapManager.zoom.toFixed(3);
      this.statusItemRotationTarget.innerText = `${radiansToDegrees(this.mapManager.rotation).toFixed(1)}°`;
      this.statusItemPanelsCountTarget.innerText = `${this.focusedRoofPlane.panelsCount}/${this.project.panelsCount}`;
    }, 500);
  }

  startDrawMode(event) {
    if (event && event.currentTarget && toolbarBtn.isDisabled(event.currentTarget)) return;
    this.editorMode = EDITOR_MODE_DRAW;

    this.measureInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.selectInteractionManager.remove();
    this.drawInteractionManager.add();

    this.setEnabledForBtnGroup("select", false);
    this.setEnabledForBtnGroup("selectSegments", false);
    toolbarBtn.disable(this.addThermalExpansionGapMenuBtnTarget);
    this.setEnabledForBtnGroup("draw", true);

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startSelectMode(event) {
    if (event && event.currentTarget && toolbarBtn.isDisabled(event.currentTarget)) return;
    this.editorMode = EDITOR_MODE_SELECT;

    this.measureInteractionManager.remove();
    this.selectInteractionManager.add();
    this.translateInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.drawInteractionManager.remove();

    this.setEnabledForBtnGroup("select", true);
    this.setEnabledForBtnGroup("selectSegments", this.selectType === SELECT_TYPE_SEGMENTS);
    this.setEnabledForBtnGroup("draw", false);

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startPanMapMode(_event) {
    this.editorMode = EDITOR_MODE_PAN_MAP;

    this.measureInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.selectInteractionManager.clearDragBoxInteraction();
    this.drawInteractionManager.remove();

    this.setEnabledForBtnGroup("select", false);
    this.setEnabledForBtnGroup("selectSegments", false);
    toolbarBtn.disable(this.addThermalExpansionGapMenuBtnTarget);
    this.setEnabledForBtnGroup("draw", false);

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startMeasureMode(_event) {
    this.editorMode = EDITOR_MODE_MEASURE;

    this.modifyInteractionManager.remove();
    this.selectInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.measureInteractionManager.add();
    this.drawInteractionManager.remove();

    this.setEnabledForBtnGroup("select", false);
    this.setEnabledForBtnGroup("selectSegments", false);
    toolbarBtn.disable(this.addThermalExpansionGapMenuBtnTarget);
    this.setEnabledForBtnGroup("draw", false);

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  setLandscapePanelOrientation(_event) {
    this.panelOrientation = PANEL_ORIENTATION_LANDSCAPE;
  }

  setPortraitPanelOrientation(_event) {
    this.panelOrientation = PANEL_ORIENTATION_PORTRAIT;
  }

  setSelectToSegment(event) {
    if (event && toolbarBtn.isDisabled(event.currentTarget)) return;
    if (!event) {
      this.startSelectMode();
      this.editorModeMenuTarget.selectGroupController.selectOption(EDITOR_MODE_SELECT);
      this.selectTypeMenuTarget.selectGroupController.selectOption(SELECT_TYPE_SEGMENTS);
    }

    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();

    this.selectType = SELECT_TYPE_SEGMENTS;
    this.selectInteractionManager.setSelectType(this.selectType);
    this.setEnabledForBtnGroup("selectSegments", true);
    toolbarBtn.disable(this.addThermalExpansionGapMenuBtnTarget);
  }

  setSelectToPanel(event) {
    if (event && toolbarBtn.isDisabled(event.currentTarget)) return;
    if (!event) {
      this.startSelectMode();
      this.editorModeMenuTarget.selectGroupController.selectOption(EDITOR_MODE_SELECT);
      this.selectTypeMenuTarget.selectGroupController.selectOption(SELECT_TYPE_PANELS);
    }

    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();

    this.selectType = SELECT_TYPE_PANELS;
    this.selectInteractionManager.setSelectType(this.selectType);
    this.setEnabledForBtnGroup("selectSegments", false);
    this.enableDisableThermalExpansionButton();
  }

  setSelectToMeasure(event) {
    if (event && toolbarBtn.isDisabled(event.currentTarget)) return;
    if (!event) {
      this.startSelectMode();
      this.editorModeMenuTarget.selectGroupController.selectOption(EDITOR_MODE_SELECT);
      this.selectTypeMenuTarget.selectGroupController.selectOption(SELECT_TYPE_MEASURES);
    }

    this.modifyInteractionManager.add();
    this.translateInteractionManager.add();

    this.selectType = SELECT_TYPE_MEASURES;
    this.selectInteractionManager.setSelectType(this.selectType);
    this.setEnabledForBtnGroup("selectSegments", false);
    toolbarBtn.disable(this.addThermalExpansionGapMenuBtnTarget);
  }

  setSelectToThermalExpansion(event) {
    if (event && toolbarBtn.isDisabled(event.currentTarget)) return;
    if (!event) {
      this.startSelectMode();
      this.editorModeMenuTarget.selectGroupController.selectOption(EDITOR_MODE_SELECT);
      this.selectTypeMenuTarget.selectGroupController.selectOption(SELECT_TYPE_THERMAL_EXPANSIONS);
    }

    this.modifyInteractionManager.add();
    this.translateInteractionManager.add();

    this.selectType = SELECT_TYPE_THERMAL_EXPANSIONS;
    this.selectInteractionManager.setSelectType(this.selectType);
    this.setEnabledForBtnGroup("selectSegments", false);
    this.enableDisableThermalExpansionButton();
  }

  nudgeUp(event) {
    this.nudge(event, "up");
  }

  nudgeRight(event) {
    this.nudge(event, "right");
  }

  nudgeDown(event) {
    this.nudge(event, "down");
  }

  nudgeLeft(event) {
    this.nudge(event, "left");
  }

  nudge(event, direction) {
    if (event.currentTarget !== document && toolbarBtn.isDisabled(event.currentTarget)) return;

    event.preventDefault();
    const inches = this.nudgeInches(event);
    this.selectInteractionManager.nudge(direction, inches);
  }

  nudgeInches(event) {
    let inches = 6;
    if (event.shiftKey) inches = 24;
    if (event.altKey) inches = 1;

    return inches;
  }

  alignTop(event) {
    this.align(event, "top");
  }

  alignBottom(event) {
    this.align(event, "bottom");
  }

  alignLeft(event) {
    this.align(event, "left");
  }

  alignRight(event) {
    this.align(event, "right");
  }

  align(event, alignTo) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.align(alignTo);
  }

  adjoiningAlignHorizontal(event) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    const type = event.altKey ? ADJOINMENT_TYPE_CONTOUR_LEGALITY : ADJOINMENT_TYPE_REGULAR;
    this.selectInteractionManager.adjoiningAlign("horizontal", type);
  }

  adjoiningAlignVertical(event) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    const type = event.altKey ? ADJOINMENT_TYPE_CONTOUR_LEGALITY : ADJOINMENT_TYPE_REGULAR;
    this.selectInteractionManager.adjoiningAlign("vertical", type);
  }

  mergeHorizontal(event) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.merge("horizontal");
  }

  mergeVertical(event) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.merge("vertical");
  }

  addRowOnTop(event) {
    this.addToSegment(event, "top");
  }

  addRowOnBottom(event) {
    this.addToSegment(event, "bottom");
  }

  addColumnOnLeft(event) {
    this.addToSegment(event, "left");
  }

  addColumnOnRight(event) {
    this.addToSegment(event, "right");
  }

  addToSegment(event, side) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.addToSegment(side);
  }

  addThermalExpansionGapOnLeft(event) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.addThermalExpansion("left");
  }

  addThermalExpansionGapOnRight(event) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.addThermalExpansion("right");
  }

  enableDisableThermalExpansionButton() {
    if (this.needsThermalExpansion || !this.allowDisablingThermalExpansionBtns) {
      toolbarBtn.enable(this.addThermalExpansionGapMenuBtnTarget);
    } else {
      toolbarBtn.disable(this.addThermalExpansionGapMenuBtnTarget);
    }
  }

  setRafterOffset(newRafterOffset) {
    this.focusedRoofPlane.setRafterOffset(newRafterOffset);
    this.markDirty();
    this.mapModelSynchronizer.reRenderRafters();
    this.addSnapshotToUndoQueue();
  }

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(event, onSuccess) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.deselectSelected();
    toolbarBtn.showSpinner(this.saveBtnTarget);

    const contourCodesClearer = new SegmentsContourCodesClearer({ controller: this });
    contourCodesClearer.clear();

    const currentPageCallback = (json) => {
      if (onSuccess) onSuccess();

      if (this.undoQueue) {
        this.clearUndoQueue();
        this.addSnapshotToUndoQueue(false);
      }

      applyDomUpdatesFromResponse(json);

      this.mapManager.checkLegality();

      this.markClean();

      this.focusedRoofPlane.roofSections.forEach((roofSection) => {
        roofSection.segments.forEach((segment) => {
          reRenderSegment(this, segment);
        });
      });

      setTimeout(() => {
        toolbarBtn.hideSpinner(this.saveBtnTarget);
      }, 500);
    };

    if (this.focusedRoofPlane.stale) {
      this.focusedRoofPlane.setStale(false);

      // Manually trigger roof section feature styles to reload
      this.mapManager.roofSectionsVectorSource.getFeatures().forEach((f) => f.changed());
    }

    this.project.save({
      path: this.savePath,
      onSuccess: currentPageCallback,
      onValidationError: this.onValidationError,
      onServerError: this.onServerError,
      includeProjectSite: false,
      includeZonesAndModulePositions: true,
      includeUploadedBuildingImages: false,
    });
  }
}
