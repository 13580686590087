import { getCenter } from "ol/extent";
import { Point } from "ol/geom";
import { Fill, Icon, Stroke, Style } from "ol/style";

export const buildingImagePlacementStyleFunction = (placement) => {
  const iconStyle = new Style({
    geometry: (feature) => {
      return new Point(getCenter(feature.getGeometry().getExtent()));
    },
    image: new Icon({
      src: placement.imagePath,
      crossOrigin: "anonymous",
    }),
  });

  const styles = [
    new Style({
      stroke: new Stroke({
        width: 1,
        // color: "#f00", // For debugging
        color: "transparent",
      }),
      fill: new Fill({
        color: "transparent",
      }),
      zIndex: 1,
    }),
    iconStyle,
  ];

  return (feature, resolution) => {
    function distance(p1, p2) {
      const dx = p2[0] - p1[0];
      const dy = p2[1] - p1[1];
      return Math.sqrt(dx * dx + dy * dy);
    }

    const iconImage = iconStyle.getImage();
    const icon = iconImage.getImage();

    const coords = feature.getGeometry().getCoordinates()[0];
    const width = distance(coords[0], coords[1]);
    const height = distance(coords[1], coords[2]);

    const scaleX = width / resolution / icon.naturalWidth;
    const scaleY = height / resolution / icon.naturalHeight;

    iconImage.setScale([scaleX, scaleY]);
    iconImage.setRotation(feature.get("imageRotation"));
    iconImage.setOpacity(feature.get("selected") ? 0.7 : 1.0);

    return styles;
  };
};
