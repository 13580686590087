import MapBaseController from "./map_base_controller";

import MapModelSynchronizer from "../../../../pr/map/map-model-synchronizers/panel-properties";
import MapManager from "../../../../pr/map/map-managers/panel-properties";
import MeasureInteractionManager from "../../../../da/map/interaction-managers/measure/base";
import SelectInteractionManager from "../../../../pr/map/interaction-managers/select/panel-properties";
import SnapInteractionManager from "../../../../pr/map/interaction-managers/snap/panel-properties";
import TranslateInteractionManager from "../../../../da/map/interaction-managers/translate/base";
import ModifyInteractionManager from "../../../../da/map/interaction-managers/modify/base";
import { radiansToDegrees } from "../../../../helpers/geometry";

import * as toolbarSelectGroup from "../../../../da/layout-editor/helpers/toolbar-select-group";
import * as toolbarBtn from "../../../../da/layout-editor/helpers/toolbar-btns";

import {
  EDITOR_MODE_PAINT_ZONES,
  EDITOR_MODE_PAINT_MODULE_POSITIONS,
  EDITOR_MODE_MEASURE,
  EDITOR_MODE_PAN_MAP,
  EDITOR_MODE_SELECT,
  EDITOR_MODE_PAINT_CONTOUR,
} from "../../../../da/layout-editor/helpers/toolbar-constants";
import { reRenderSegment } from "../../../../pr/map/modification-helpers/segments/helpers";
import { applyDomUpdatesFromResponse } from "../../../../helpers/api-dom-helpers";
import { ROOF_SHAPE_GABLE } from "../../../../pr/models/project-model";
import {
  ZONES_710,
  ZONES_716_GABLE,
  ZONES_716_HIP,
  ZONE_1,
  ZONE_2,
  ZONE_2E,
  ZONE_2N,
  ZONE_2R,
  ZONE_3,
  ZONE_3E,
  ZONE_3R,
  MODULE_POSITION_N,
  MODULE_POSITION_EXP,
  MODULE_POSITION_EDG,
  MODULE_POSITION_EDGN,
  MODULE_POSITION_EDGS,
} from "../../../../pr/helpers/zones-and-module-positions";
import { MEASURE_DATA_TYPE, PANEL_DATA_TYPE } from "../../../../da/map/data-types";
import SegmentsContourCodesClearer from "../../../../pr/map/modification-helpers/segments/contour/codes-clearer";

export default class MapPanelPropertiesController extends MapBaseController {
  static targets = [
    "map",
    "compass",
    "statusItemZoom",
    "statusItemRotation",
    "statusItemSelections",
    "statusItemPanelsCount",
    "paintZonesBtn",
    "paintModulePositionsBtn",
    "measureBtn",
    "zonesMenuBtn",
    "zone1Btn",
    "zone2Btn",
    "zone2eBtn",
    "zone2nBtn",
    "zone2rBtn",
    "zone3Btn",
    "zone3eBtn",
    "zone3rBtn",
    "modulePositionsMenuBtn",
    "modulePositionNBtn",
    "modulePositionEXPBtn",
    "modulePositionEDGBtn",
    "modulePositionEDGNBtn",
    "modulePositionEDGSBtn",
    "paintContourMenuContainer",
    "paintContourBtn",
    "paintContourMenuBtn",
    "paintContourWestBtn",
    "paintContourEastBtn",
    "paintContourSouthBtn",
    "panMapBtn",
    "editorModeSelectGroupContainer",
    "undoBtn",
    "redoBtn",
  ];

  static values = {
    usingHiddenEndClamp: Boolean,
  };

  connect() {
    super.connect();

    this.focusedRoofPlaneUuid = this.element.dataset.focusedRoofPlaneUuid;
    this.focusedRoofPlane = this.project.getRoofPlane(this.focusedRoofPlaneUuid);
    this.pageRoofPlanes = [this.focusedRoofPlane];

    this.renderSegmentsWithZones = true;

    this.setupToolbars();
    this.mapSpecificConnect();
    this.setupMapInteractionManagers();
    this.initializeEditorMode();
    this.populateStatusbar();

    setTimeout(() => {
      this.alignViewToFeature(this.mapModelSynchronizer.getFeatureForRoofPlane(this.focusedRoofPlane));
      this.mapManager.rotateTo(this.focusedRoofPlane.eaveRotationAngleRadians);
    }, 200);

    setTimeout(() => {
      this.addSnapshotToUndoQueue(false);
    }, 1000);

    document.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    if (event.key === "Escape") {
      this.measureInteractionManager.finish();
    }
  };

  get contourIsAvailable() {
    return this.hasPaintContourMenuBtnTarget;
  }

  setupToolbars() {
    this.btnGroups = {
      paintZones: [this.zonesMenuBtnTarget],
      paintModulePositions: [],
    };

    if (this.contourIsAvailable) {
      this.btnGroups.paintContour = [this.paintContourMenuBtnTarget, this.paintContourSouthBtnTarget];

      if (this.usingHiddenEndClampValue) {
        this.btnGroups.paintContour.push(this.paintContourWestBtnTarget);
        this.btnGroups.paintContour.push(this.paintContourEastBtnTarget);
      } else {
        toolbarBtn.disable(this.paintContourWestBtnTarget);
        toolbarBtn.disable(this.paintContourEastBtnTarget);
      }
      // Give the enable/disable process some time to finish
      setTimeout(() => {
        [this.paintContourWestBtnTarget, this.paintContourEastBtnTarget, this.paintContourSouthBtnTarget].forEach(
          (btn) => this.#changeTooltipOfContourOptionBtn(btn),
        );
      }, 200);
    }

    let zones;

    if (this.project.is722) {
      zones = ZONES_710;
      this.paintModulePositions();
    } else if (this.project.is716) {
      zones = this.project.detail.roofShape === ROOF_SHAPE_GABLE ? ZONES_716_GABLE : ZONES_716_HIP;
      this.paintModulePositions();
    } else {
      zones = ZONES_710;
    }

    zones.forEach((zone) => {
      this.btnGroups.paintZones.push(this[`zone${zone}BtnTarget`]);
    });

    this.focusedRoofPlaneUuid = this.element.dataset.focusedRoofPlaneUuid;
    this.focusedRoofPlane = this.project.getRoofPlane(this.focusedRoofPlaneUuid);
    this.slopeAdjustedMeasures = true;

    this.editorMode = toolbarSelectGroup.getState("prPanelPropertiesEditorMode", EDITOR_MODE_PAINT_ZONES);

    if (this.focusedRoofPlane.useCritterGuard && this.editorMode === EDITOR_MODE_PAINT_CONTOUR) {
      this.editorMode = EDITOR_MODE_PAINT_ZONES;
      setTimeout(() => {
        this.editorModeSelectGroupContainerTarget.selectGroupController.selectOption("paintZones");
      }, 200);
    }

    if (!this.project.is716Or722 && this.paintModulePositionsSelection === EDITOR_MODE_PAINT_MODULE_POSITIONS) {
      this.editorMode = EDITOR_MODE_PAINT_ZONES;
    }

    const zonesSelectionKey = this.project.is716 ? "prPaintZones716Selection" : "prPaintZones710Selection";
    this.paintZonesSelection = toolbarSelectGroup.getState(zonesSelectionKey, ZONE_1);
    if (this.project.is716Or722) {
      this.paintModulePositionsSelection = toolbarSelectGroup.getState(
        "prPaintModulePositionsSelection",
        MODULE_POSITION_N,
      );
    }
  }

  paintModulePositions() {
    this.btnGroups.paintModulePositions.push(this.modulePositionsMenuBtnTarget);
    this.btnGroups.paintModulePositions.push(this.modulePositionNBtnTarget);
    this.btnGroups.paintModulePositions.push(this.modulePositionEXPBtnTarget);
    this.btnGroups.paintModulePositions.push(this.modulePositionEDGBtnTarget);
    this.btnGroups.paintModulePositions.push(this.modulePositionEDGNBtnTarget);
    this.btnGroups.paintModulePositions.push(this.modulePositionEDGSBtnTarget);
  }

  setupMapInteractionManagers() {
    this.selectInteractionManager = new SelectInteractionManager(this);
    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();
    this.measureInteractionManager = new MeasureInteractionManager(this);
    this.translateInteractionManager = new TranslateInteractionManager(this);
    this.modifyInteractionManager = new ModifyInteractionManager(this);
  }

  initializeEditorMode() {
    switch (this.editorMode) {
      case EDITOR_MODE_PAINT_ZONES:
        this.startPaintZonesMode();
        break;
      case EDITOR_MODE_PAINT_MODULE_POSITIONS:
        if (this.project.is716Or722) {
          this.startPaintModulePositionsMode();
        } else {
          this.startPaintZonesMode();
        }
        break;
      case EDITOR_MODE_PAINT_CONTOUR:
        if (this.contourIsAvailable) {
          this.startPaintContourMode();
        } else {
          this.startPaintZonesMode();
        }
        break;
      case EDITOR_MODE_MEASURE:
        this.startMeasureMode();
        break;
      case EDITOR_MODE_PAN_MAP:
        this.startPanMapMode();
        break;
      case EDITOR_MODE_SELECT:
        this.startSelectMode();
        break;
      default:
        this.startPaintZonesMode();
    }
  }

  startPaintZonesMode(event) {
    if (event && event.currentTarget && toolbarBtn.isDisabled(event.currentTarget)) return;

    this.measureInteractionManager.remove();
    this.selectInteractionManager.remove(); // First remove it to clear out
    this.selectInteractionManager.add(PANEL_DATA_TYPE);
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.editorMode = EDITOR_MODE_PAINT_ZONES;
    this.setEnabledForBtnGroup("paintZones", true);
    if (this.contourIsAvailable) this.setEnabledForBtnGroup("paintContour", false);
    if (this.project.is716Or722) {
      this.setEnabledForBtnGroup("paintModulePositions", false);
    }

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startPaintModulePositionsMode(event) {
    if (event && event.currentTarget && toolbarBtn.isDisabled(event.currentTarget)) return;

    this.measureInteractionManager.remove();
    this.selectInteractionManager.remove(); // First remove it to clear out
    this.selectInteractionManager.add(PANEL_DATA_TYPE);
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.editorMode = EDITOR_MODE_PAINT_MODULE_POSITIONS;
    if (this.project.is716Or722) {
      this.setEnabledForBtnGroup("paintModulePositions", true);
    }
    this.setEnabledForBtnGroup("paintZones", false);
    if (this.contourIsAvailable) this.setEnabledForBtnGroup("paintContour", false);

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  get isEditorModePaintZones() {
    return this.editorMode === EDITOR_MODE_PAINT_ZONES;
  }

  get isEditorModePaintContour() {
    return this.editorMode === EDITOR_MODE_PAINT_CONTOUR;
  }

  startPaintContourMode(event) {
    if (event && event.currentTarget && toolbarBtn.isDisabled(event.currentTarget)) return;

    this.measureInteractionManager.remove();
    this.selectInteractionManager.remove(); // First remove it to clear out
    this.selectInteractionManager.add(PANEL_DATA_TYPE);
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.editorMode = EDITOR_MODE_PAINT_CONTOUR;
    this.setEnabledForBtnGroup("paintZones", false);
    if (this.project.is716Or722) {
      this.setEnabledForBtnGroup("paintModulePositions", false);
    }
    this.setEnabledForBtnGroup("paintContour", true);

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startPanMapMode(_event) {
    this.measureInteractionManager.remove();
    this.selectInteractionManager.clearDragBoxInteraction();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.editorMode = EDITOR_MODE_PAN_MAP;
    this.setEnabledForBtnGroup("paintZones", false);
    if (this.project.is716Or722) {
      this.setEnabledForBtnGroup("paintModulePositions", false);
    }

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startMeasureMode(_event) {
    this.selectInteractionManager.remove();
    this.measureInteractionManager.add();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    if (this.project.is716Or722) {
      this.setEnabledForBtnGroup("paintModulePositions", false);
    }
    this.setEnabledForBtnGroup("paintZones", false);

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startSelectMode(_event) {
    this.selectInteractionManager.remove(); // First remove it to clear out
    this.selectInteractionManager.add(MEASURE_DATA_TYPE);
    this.measureInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.editorMode = EDITOR_MODE_SELECT;
    if (this.project.is716Or722) {
      this.setEnabledForBtnGroup("paintModulePositions", false);
    }
    this.setEnabledForBtnGroup("paintZones", false);

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  mapSpecificConnect() {
    this.mapModelSynchronizer = new MapModelSynchronizer(this);
    this.mapManager = new MapManager(this);
    this.mapManager.add();
  }

  populateStatusbar() {
    // When a value like rotation updates, we need to wait for OL to finish doing its thing
    setTimeout(() => {
      this.statusItemZoomTarget.innerText = this.mapManager.zoom.toFixed(3);
      this.statusItemRotationTarget.innerText = `${radiansToDegrees(this.mapManager.rotation).toFixed(1)}°`;
      this.statusItemPanelsCountTarget.innerText = `${this.focusedRoofPlane.panelsCount}/${this.project.panelsCount}`;
    }, 500);
  }

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(event, onSuccess) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.deselectSelected();
    toolbarBtn.showSpinner(this.saveBtnTarget);

    const contourCodesClearer = new SegmentsContourCodesClearer({ controller: this });
    contourCodesClearer.clear();

    const currentPageCallback = (json) => {
      if (onSuccess) onSuccess();
      if (this.undoQueue) {
        this.clearUndoQueue();
        this.addSnapshotToUndoQueue(false);
      }

      applyDomUpdatesFromResponse(json);
      this.markClean();

      this.focusedRoofPlane.roofSections.forEach((roofSection) => {
        roofSection.segments.forEach((segment) => {
          reRenderSegment(this, segment);
        });
      });

      setTimeout(() => {
        toolbarBtn.hideSpinner(this.saveBtnTarget);
      }, 500);
    };

    this.project.save({
      path: this.savePath,
      onSuccess: currentPageCallback,
      onValidationError: this.onValidationError,
      onServerError: this.onServerError,
      includeProjectSite: false,
      includeZonesAndModulePositions: true,
      includeUploadedBuildingImages: false,
    });
  }

  setZone1(event) {
    this.setZone(event, ZONE_1);
  }

  setZone2(event) {
    this.setZone(event, ZONE_2);
  }

  setZone2e(event) {
    this.setZone(event, ZONE_2E);
  }

  setZone2n(event) {
    this.setZone(event, ZONE_2N);
  }

  setZone2r(event) {
    this.setZone(event, ZONE_2R);
  }

  setZone3(event) {
    this.setZone(event, ZONE_3);
  }

  setZone3e(event) {
    this.setZone(event, ZONE_3E);
  }

  setZone3r(event) {
    this.setZone(event, ZONE_3R);
  }

  setZone(event, value) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.paintZonesSelection = value;
    if (event.shiftKey) this.selectInteractionManager.setZone(value);
    if (this.focusedRoofPlane.needsSave) this.markDirty();
  }

  setModulePositionN(event) {
    this.setModulePosition(event, MODULE_POSITION_N);
  }

  setModulePositionEXP(event) {
    this.setModulePosition(event, MODULE_POSITION_EXP);
  }

  setModulePositionEDG(event) {
    this.setModulePosition(event, MODULE_POSITION_EDG);
  }

  setModulePositionEDGN(event) {
    this.setModulePosition(event, MODULE_POSITION_EDGN);
  }

  setModulePositionEDGS(event) {
    this.setModulePosition(event, MODULE_POSITION_EDGS);
  }

  get contourPaintDirections() {
    return this.paintContourMenuContainerTarget.multiSelectGroupController.selectedOptions;
  }

  setModulePosition(event, value) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.paintModulePositionsSelection = value;
    if (event.shiftKey) this.selectInteractionManager.setModulePosition(value);
    if (this.focusedRoofPlane.needsSave) this.markDirty();
  }

  paintContourSouthClicked(_event) {
    this.#changeTooltipOfContourOptionBtn(this.paintContourSouthBtnTarget);
  }

  paintContourEastClicked(_event) {
    this.#changeTooltipOfContourOptionBtn(this.paintContourEastBtnTarget);
  }

  paintContourWestClicked(_event) {
    this.#changeTooltipOfContourOptionBtn(this.paintContourWestBtnTarget);
  }

  #changeTooltipOfContourOptionBtn(btn) {
    const currentValue = btn.dataset["components-IrTooltipContentValue"];

    let newTooltip;
    if (toolbarBtn.isActive(btn)) {
      newTooltip = currentValue.replace("Remove", "Add");
    } else {
      newTooltip = currentValue.replace("Add", "Remove");
    }

    // The setContent method updates the display content in real time, but
    // we still need to set the data attribute for the value to persist after
    // the tooltip hides.
    btn._tippy.setContent(newTooltip);
    btn.dataset["components-IrTooltipContentValue"] = newTooltip;
  }
}
