import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { defaults as interactionDefaults } from "ol/interaction";

import { arraysStyle } from "../../da/map/styles/arrays";
import { obstructionStyle, obstructionBufferStyle } from "../../da/map/styles/obstructions";
import { isARoofSection, isARoofPlane, isAnObstruction } from "../../da/map/ol-helpers";
import { ARRAY_DATA_TYPE, OBSTRUCTION_BUFFER_DATA_TYPE, OBSTRUCTION_DATA_TYPE } from "../../da/map/data-types";

import DaMapManager from "../../da/map/map-manager";

import { adjustArrayAfterNudge } from "./modification-helpers/roof-section";
import CellPositioner from "./panel-grid/cell-positioner";
import DefaultRoofSectionsBuilder from "./default-roof-sections-builder";

import * as events from "../../da/map/events";
import { logger } from "../../helpers/app";
import SetbackLegalityChecker from "../../pr/map/legality-checkers/setback";

export default class MapManager extends DaMapManager {
  buildArraysVectorSource() {
    this.arraysVectorSource = new VectorSource({
      features: this.arraysFeatures,
    });
  }

  get arraysFeatures() {
    let limitTo = "all";
    if (this.controller.isRoofPlanesPage) {
      limitTo = "none";
    } else if (this.controller.isEditArrayPage) {
      limitTo = "roofSection";
    }
    return this.mapModelSynchronizer.featuresForArrays(limitTo, this.project.focusedRoofSection);
  }

  get arraysVectorLayer() {
    if (!this.memoArraysVectorLayer) {
      this.memoArraysVectorLayer = new VectorLayer({
        source: this.arraysVectorSource,
        style: (feature) =>
          arraysStyle(feature, {
            hideEmptyCells: this.controller.hideEmptyCells,
            isProjectReport: this.controller.isProjectReport,
          }),
        zIndex: 40,
      });
      this.memoArraysVectorLayer.set("dataType", ARRAY_DATA_TYPE);
    }
    return this.memoArraysVectorLayer;
  }

  addArraysVectorLayer() {
    this.map.addLayer(this.arraysVectorLayer);
  }

  clearArrayFeatures() {
    this.arraysVectorSource.clear();
  }

  renderArrayFeatures() {
    this.arraysVectorSource.addFeatures(this.arraysFeatures);
  }

  add() {
    this.buildRoofPlanesVectorSource();
    this.buildObstructionsVectorSource();
    this.buildObstructionBuffersVectorSource();
    this.buildRoofSectionsVectorSource();
    this.buildArraysVectorSource();
    this.buildMeasuresVectorSource();
    this.buildRulersVectorSource();
    const mapParams = {
      layers: [
        this.tileLayer,
        this.roofPlanesVectorLayer,
        this.obstructionBuffersVectorLayer,
        this.obstructionsVectorLayer,
        this.measuresVectorLayer,
        this.rulersVectorLayer,
        this.visualMarkersVectorLayer,
      ],
    };

    if (this.controller.useGuideLines) {
      this.buildGuideLinesVectorSource();
      this.buildGuideLinesIntersectionCirclesVectorSource();

      mapParams.layers.push(this.guideLinesVectorLayer);
      mapParams.layers.push(this.guideLinesIntersectionCirclesVectorLayer);
    }

    if (this.controller.viewOnlyMap) {
      mapParams.interactions = interactionDefaults({ doubleClickZoom: false, mouseWheelZoom: false });
    }

    this.map = this.buildMap(mapParams);

    this.addBuildingImagePlacementLayers();

    if (this.controller.viewOnlyMap) this.addMouseWheelZoomWhenCtrlKeyPressedInteraction();

    this.map.on("moveend", this.onMoveEnd);
    this.map.on("click", this.keepTrackOfDrawingCoordinates);
    this.map.on(events.AFTER_MAP_FEATURES_RENDERING_EVENT, (event) => {
      logger(`Dispatch ${events.AFTER_MAP_FEATURES_RENDERING_EVENT} called from ${event.calledFrom}`);

      this.controller.addSnapshotToUndoQueue();

      if (this.controller.isRoofSectionsPage) {
        this.#checkSetbacksLegality();
      }
    });
  }

  buildObstructionsVectorSource() {
    this.obstructionsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.featuresForObstructions(),
    });
    this.obstructionsVectorSource.on("addfeature", this.autoDeleteFlaggedFeatures);
  }

  get obstructionsVectorLayer() {
    if (!this.memoObstructionVectorLayer) {
      this.memoObstructionVectorLayer = new VectorLayer({
        source: this.obstructionsVectorSource,
        style: (feature) => obstructionStyle(feature, this.controller.project),
        zIndex: 17,
      });
      this.memoObstructionVectorLayer.set("dataType", OBSTRUCTION_DATA_TYPE);
    }
    return this.memoObstructionVectorLayer;
  }

  buildObstructionBuffersVectorSource() {
    this.obstructionBuffersVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.featuresForObstructionBuffers(),
    });
    this.obstructionBuffersVectorSource.on("addfeature", this.autoDeleteFlaggedFeatures);
  }

  get obstructionBuffersVectorLayer() {
    if (!this.memoObstructionBufferVectorLayer) {
      this.memoObstructionBufferVectorLayer = new VectorLayer({
        source: this.obstructionBuffersVectorSource,
        style: (feature) => obstructionBufferStyle(feature, this.controller.project),
        zIndex: 15,
      });
      this.memoObstructionBufferVectorLayer.set("dataType", OBSTRUCTION_BUFFER_DATA_TYPE);
    }
    return this.memoObstructionBufferVectorLayer;
  }

  #checkSetbacksLegality() {
    this.setbacksVectorSource.forEachFeature((feature) => {
      new SetbackLegalityChecker(feature, this.mapModelSynchronizer).check();
    });
  }

  addDrawingCoordinateToProjectModel(event) {
    super.addDrawingCoordinateToProjectModel(event);

    this.dispatchAfterMapFeaturesRendering({
      calledFrom: "BxMapManager#addDrawingCoordinateToProjectModel",
    });
  }

  clearFeaturesBeforeSnapshotUpdate() {
    this.clearRoofPlaneFeatures();
    this.clearRoofSectionFeatures();
    this.clearArrayFeatures();
    this.clearSetbackFeatures();
  }

  renderFeaturesAfterSnapshotUpdate() {
    this.renderRoofPlaneFeatures();
    this.renderRoofSectionFeatures();
    this.renderArrayFeatures();
    this.renderSetbackFeatures();
  }

  clearSetbackFeatures() {
    this.setbacksVectorSource.clear();
  }

  renderSetbackFeatures() {
    this.setbacksVectorSource.addFeatures(this.mapModelSynchronizer.loadSetbacks());
  }

  buildNewDefaultRoofSectionsAndSetbacks(roofPlaneWithPriorLayout) {
    // have to add this after the map is created because we need the map to
    // calculate the default roof sections
    this.roofSectionsBuilder = new DefaultRoofSectionsBuilder(
      this.controller,
      this.map,
      this.roofPlanesFeatures,
      roofPlaneWithPriorLayout,
    );

    // TODO: Optimization only add setbacks that are newly created for
    //       newly constructed default roof sections.
    this.updateSetbacksVectorSource();
  }

  // If you're trying to add a roof section outside of any roof plane we
  // abort the adding of the feature
  autoDeleteFlaggedFeatures = (event) => {
    const feature = event.feature;

    if (feature.get("flaggedForAutoDeleteByVectorSource")) {
      if (isARoofPlane(feature)) {
        this.roofPlanesVectorSource.removeFeature(feature);
      } else if (isARoofSection(feature)) {
        this.roofSectionsVectorSource.removeFeature(feature);
      } else if (isAnObstruction(feature)) {
        this.obstructionsVectorSource.removeFeature(feature);
      } else {
        console.error("Request to auto delete a feature, but don't know dataType", feature);
      }
    }
  };

  nudgeAlongAzimuth(roofSection, rsFeature, inchesToNudge) {
    this.nudgeRelativeToAzimuth(roofSection, rsFeature, inchesToNudge, "unitHeightVector");
  }

  nudgePerpendicularToAzimuth(roofSection, rsFeature, inchesToNudge) {
    this.nudgeRelativeToAzimuth(roofSection, rsFeature, inchesToNudge, "unitWidthVector");
  }

  nudgeRelativeToAzimuth(roofSection, rsFeature, inchesToNudge, direction) {
    const cellPositioner = new CellPositioner(this.project, roofSection);

    const adjustmentVector = cellPositioner[direction].times(inchesToNudge);

    const key = `${direction}Cartesian`;
    const adjustmentVectorCartesian = cellPositioner[key].times(inchesToNudge);

    roofSection.adjustOffset(adjustmentVector, adjustmentVectorCartesian);
    adjustArrayAfterNudge(roofSection, this.project, this);

    this.dispatchAfterMapFeaturesRendering({ calledFrom: "BxMapManager#nudgeRelativeToAzimuth" });
  }

  nudgeReset(roofSection) {
    roofSection.resetOffset();
    adjustArrayAfterNudge(roofSection, this.project, this);

    this.dispatchAfterMapFeaturesRendering({ calledFrom: "BxMapManager#nudgeReset" });
  }

  onMoveEndHandler(event) {
    const result = super.onMoveEndHandler(event);
    if (!result) return;

    if (this.controller.draggingAzimuth) return;
    if (this.controller.undoRedoInProgress) return;

    this.dispatchAfterMapFeaturesRendering({ calledFrom: "BxMapManager#onMoveEnd" });
  }

  get obstructionsFeatures() {
    return this.obstructionsVectorSource.getFeatures();
  }

  get obstructionBuffersFeatures() {
    return this.obstructionBuffersVectorSource.getFeatures();
  }
}
