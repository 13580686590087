import { getParent, types } from "mobx-state-tree";

import WithLatLngPoints from "./with-lat-lng-points";
import WithDirtyTracking from "./with-dirty-tracking";
import { pointsForSave } from "./points-utilities";

const BuildingImagePlacementBase = types
  .model("BuildingImagePlacementModel", {
    id: types.maybe(types.integer),
    uuid: types.identifier,
    uploadedBuildingImageId: types.maybe(types.integer),
    imageRotation: types.optional(types.number, 0),
  })
  .views((self) => ({
    get needsSave() {
      return self.dirty;
    },
    get uploadedBuildingImage() {
      return getParent(self, 2);
    },
    get imagePath() {
      return self.uploadedBuildingImage.imagePath;
    },
    get isPersisted() {
      return !!self.id;
    },
  }))
  .actions((self) => ({
    flagDeleted() {
      if (self.deleted) return;

      self.deleted = true;
      self.markDirty();
    },
    setImageRotation(imageRotation) {
      self.imageRotation = imageRotation;
      self.markDirty();
    },
  }));

export function buildingImagePlacementPersistenceData(buildingImagePlacement) {
  const latLngPoints = pointsForSave(buildingImagePlacement, "latLngPoints");
  // TODO: add cartesian points
  // const cartesianPoints = pointsForSave(buildingImagePlacement, "cartesianPoints");
  const result = {
    uuid: buildingImagePlacement.uuid,
    id: buildingImagePlacement.id,
    lat_lng_points: latLngPoints,
    image_rotation: buildingImagePlacement.imageRotation,
    uploaded_building_image_id: buildingImagePlacement.uploadedBuildingImageId,
    _destroy: buildingImagePlacement.deleted,
    // cartesian_points: cartesianPoints,
  };

  return result;
}

const BuildingImagePlacementModel = types.compose(BuildingImagePlacementBase, WithLatLngPoints, WithDirtyTracking);
export default BuildingImagePlacementModel;
