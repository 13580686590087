import BaseMapManager from "./base";
import SetbackLegalityChecker from "../legality-checkers/setback";
import * as events from "../../../da/map/events";
import { logger } from "../../../helpers/app";

import { SELECT_TYPE_SETBACK } from "../../../da/layout-editor/helpers/toolbar-constants";
import { SETBACK_DATA_TYPE, MEASURE_DATA_TYPE } from "../../../da/map/data-types";

export default class SetbacksMapManager extends BaseMapManager {
  add() {
    this.buildRoofPlanesVectorSource();
    this.buildRoofSectionsVectorSource();
    this.buildSetbacksVectorSource();
    this.buildMeasuresVectorSource();
    this.buildGuideLinesVectorSource();
    this.buildGuideLinesIntersectionCirclesVectorSource();
    this.buildPanelsVectorSource();
    this.buildSegmentsVectorSource();
    this.buildRailsVectorSource();
    this.buildThermalExpansionsVectorSource();
    this.buildAdjoinmentsVectorSource();
    this.buildContourLegalityAdjoinmentsVectorSource();
    this.buildContoursVectorSource();

    this.map = this.buildMap({
      layers: [
        this.tileLayer,
        this.roofPlanesVectorLayer,
        this.measuresVectorLayer,
        this.setbacksVectorLayer,
        this.guideLinesIntersectionCirclesVectorLayer,
        this.guideLinesVectorLayer,
        this.panelsVectorLayer,
        this.segmentsVectorLayer,
        this.railsVectorLayer,
        this.thermalExpansionsVectorLayer,
        this.contoursVectorLayer,
        this.contourLegalityAdjoinmentsVectorLayer,
        this.adjoinmentsVectorLayer,
      ],
    });

    this.renderContourFeatures();
    this.addBuildingImagePlacementLayers();

    this.setProjectSiteValuesOnMoveEnd = false;

    this.map.on("moveend", this.onMoveEnd);
    this.map.on("click", this.onMapClick);
    this.map.on(events.AFTER_MAP_FEATURES_RENDERING_EVENT, (event) => {
      logger(`Dispatch ${events.AFTER_MAP_FEATURES_RENDERING_EVENT} called from ${event.calledFrom}`);

      this.checkLegality();
    });

    this.dispatchAfterMapFeaturesRendering({ calledFrom: "SetbacksMapManager#add" });
  }

  checkLegality() {
    const segmentsLegalityChecker = super.checkLegality();

    this.controller.showEncroachingWarningMessage(segmentsLegalityChecker.encroaching);

    const setbackFeature = this.setbacksVectorSource.getFeatures()[0];
    new SetbackLegalityChecker(setbackFeature, this.mapModelSynchronizer).check();
  }

  onMapClick = (event) => {
    const { coordinate } = event;
    const pixelCoordinate = this.map.getPixelFromCoordinate(coordinate);
    const featuresAtPixel = this.map.getFeaturesAtPixel(pixelCoordinate);

    const setbackFeatures = featuresAtPixel.filter((feature) => feature.get("dataType") === SETBACK_DATA_TYPE);
    const measureFeatures = featuresAtPixel.filter((feature) => feature.get("dataType") === MEASURE_DATA_TYPE);

    if (setbackFeatures.length === 0 && measureFeatures.length === 0) return;

    if (this.controller.selectType === SELECT_TYPE_SETBACK) {
      if (measureFeatures.length === 0) return;

      this.controller.setSelectToMeasure();
    } else {
      if (setbackFeatures.length === 0) return;

      this.controller.setSelectToSetback();
    }
  };
}
