import { getSnapshot, types } from "mobx-state-tree";
import sumBy from "lodash/sumBy";

import ProjectSiteModel from "./project-site-model";
import { buildAlphabeticalIdentifier } from "../../helpers/app";
import WithDirtyTracking from "./with-dirty-tracking";
import CoordinateModel from "./coordinate-model";
import UploadedBuildingImageModel from "./uploaded-building-image-model";

// Meant to be an abstract project class
// assumes that child classes will have roofPlanes of the appropriate type
const DaProjectModelBase = types
  .model("DaProjectModel", {
    id: types.maybe(types.integer),
    name: types.maybe(types.string),
    type: types.string,
    projectSite: ProjectSiteModel,
    detailId: types.number,
    panelWidth: types.number,
    panelLength: types.number,
    panelWattage: types.number,
    interRowSpacing: types.number,
    interColumnSpacing: types.number,
    powerUser: false,
    saving: false,
    drawingCoordinates: types.array(CoordinateModel),
    uploadedBuildingImages: types.array(UploadedBuildingImageModel),
  })
  .views((self) => ({
    get roofPlanesWithDefaultRoofSections() {
      return self.roofPlanes.filter((rp) => rp.hasDefaultRoofSections);
    },
    getRoofPlane(uuid) {
      return self.roofPlanes.find((roofPlane) => roofPlane.uuid === uuid);
    },
    getRoofPlaneForIdentifier(identifier) {
      return self.roofPlanes.find((roofPlane) => roofPlane.identifier === identifier);
    },
    getRoofSection(uuid) {
      return self.roofPlanes
        .map((roofPlane) => {
          return roofPlane.getRoofSection(uuid);
        })
        .find((roofSection) => roofSection !== undefined);
    },
    getUploadedBuildingImage(id) {
      return self.uploadedBuildingImages.find((ubi) => ubi.id === id);
    },
    getNextSequentialRoofPlaneIdentifier() {
      const numActiveRoofPlanes = self.roofPlanes.filter((rp) => !rp.deleted).length;
      const identifier = buildAlphabeticalIdentifier(numActiveRoofPlanes);
      return identifier;
    },
    get displayableRoofPlanes() {
      return self.roofPlanes.filter((rp) => rp.displayable);
    },
    get notDeletedRoofPlanes() {
      return self.roofPlanes.filter((rp) => rp.notDeleted);
    },
    get anyRoofPlaneNeedsSave() {
      return self.roofPlanes.some((rp) => rp.needsSave);
    },
    get anyUploadedBuildingImageNeedsSave() {
      return self.uploadedBuildingImages.some((ubi) => ubi.needsSave);
    },
    get hasDetailAndNeedsSave() {
      if (self.detail) {
        // Only the PR subclass of project has a detail
        return self.detail.needsSave;
      } else {
        return false;
      }
    },
    get needsSave() {
      return (
        self.dirty ||
        self.projectSite.needsSave ||
        self.hasDetailAndNeedsSave ||
        self.anyRoofPlaneNeedsSave ||
        self.anyUploadedBuildingImageNeedsSave
      );
    },
    get panelsCount() {
      return sumBy(self.notDeletedRoofPlanes, "panelsCount");
    },
    deletedRoofPlane(roofPlaneUuid) {
      return self.deletedRoofPlanes.find((drp) => drp.uuid === roofPlaneUuid);
    },
    get isBX() {
      return self.type === "Bx::Project";
    },
    get buildingImagePlacements() {
      return self.uploadedBuildingImages.flatMap((ubi) => {
        return ubi.buildingImagePlacements;
      });
    },
    get notDeletedBuildingImagePlacements() {
      return self.buildingImagePlacements.filter((bip) => !bip.deleted);
    },
    getBuildingImagePlacement(uuid) {
      return self.buildingImagePlacements.find((bip) => bip.uuid === uuid);
    },
  }))
  .actions((self) => ({
    setName(name) {
      self.name = name;
    },
    setDirty(dirty) {
      self.dirty = dirty;
    },
    setSaving() {
      self.saving = true;
    },
    setNotSaving() {
      self.saving = false;
    },
    destroyRoofPlane(roofPlane) {
      self.snapshotRoofPlaneDeletion(roofPlane);
      roofPlane.destroyRoofSections();
      self.roofPlanes.remove(roofPlane);
    },
    snapshotRoofPlaneDeletion(roofPlane) {
      roofPlane.setDeletedAt();
      self.deletedRoofPlanes.push(getSnapshot(roofPlane));
    },
    resequenceRoofPlanesIdentifiers() {
      const notDeletedRoofPlanes = self.roofPlanes
        .slice()
        .sort((a, b) => a.identifier.localeCompare(b.identifier))
        .filter((rp) => !rp.deleted);
      notDeletedRoofPlanes.forEach((rp, i) => {
        rp.setIdentifier(buildAlphabeticalIdentifier(i));
      });
    },
    addDrawingCoordinate(coordinate) {
      const model = CoordinateModel.createFromCoordinate(coordinate);
      self.drawingCoordinates.push(model);
      return model;
    },
    clearDrawingCoordinates() {
      self.drawingCoordinates = [];
    },
    addUploadedBuildingImage(uploadedBuildingImage) {
      const model = UploadedBuildingImageModel.create(uploadedBuildingImage);
      self.uploadedBuildingImages.push(model);
      return model;
    },
    destroyBuildingImagePlacement(uuid) {
      const placement = self.getBuildingImagePlacement(uuid);
      placement.uploadedBuildingImage.destroyPlacement(placement);
      self.markDirty();
    },
    destroyUploadedBuildingImage(id) {
      self.uploadedBuildingImages = self.uploadedBuildingImages.filter((ubi) => ubi.id !== id);
    },
  }));

const DaProjectModel = types.compose(DaProjectModelBase, WithDirtyTracking);
export default DaProjectModel;
