import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import { fromLonLat, toLonLat } from "ol/proj";

import {
  ROOF_PLANE_DATA_TYPE,
  ROOF_SECTION_DATA_TYPE,
  MEASURE_DATA_TYPE,
  OBSTRUCTION_DATA_TYPE,
  THERMAL_EXPANSION_DATA_TYPE,
} from "./data-types";
import { PROXIMITY_CUTOFF } from "../../helpers/geometry";
import { getDistance } from "ol/sphere";
import { METERS_TO_INCHES } from "./ol-geometry";

export const GeometryType = {
  POINT: "Point",
  LINE_STRING: "LineString",
  LINEAR_RING: "LinearRing",
  POLYGON: "Polygon",
  MULTI_POINT: "MultiPoint",
  MULTI_LINE_STRING: "MultiLineString",
  MULTI_POLYGON: "MultiPolygon",
  GEOMETRY_COLLECTION: "GeometryCollection",
  CIRCLE: "Circle",
};

export function isARoofPlane(feature) {
  return feature.get("dataType") === ROOF_PLANE_DATA_TYPE;
}

export function isAnObstruction(feature) {
  return feature.get("dataType") === OBSTRUCTION_DATA_TYPE;
}

export function isARoofSection(feature) {
  return feature.get("dataType") === ROOF_SECTION_DATA_TYPE;
}

export function isAMeasure(feature) {
  return feature.get("dataType") === MEASURE_DATA_TYPE;
}

export function isAThermalExpansion(feature) {
  return feature.get("dataType") === THERMAL_EXPANSION_DATA_TYPE;
}

export function isPolygon(feature) {
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();

  return geometryType === GeometryType.POLYGON;
}

export function isMultiPolygon(feature) {
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();

  return geometryType === GeometryType.MULTI_POLYGON;
}

export function isPoint(feature) {
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();

  return geometryType === GeometryType.POINT;
}

export function pointAtFeatureVertex(feature, point) {
  if (!isPolygon(feature)) return false;

  const geometry = feature.getGeometry();
  const polygonCoordinates = geometry.getLinearRing(0).getCoordinates();

  return polygonCoordinates.find((pc) => pc[0] === point[0] && pc[1] === point[1]);
}

export function removeFeaturesMatchingUuid(vectorSource, uuid) {
  removeFeaturesMatchingKeyAndValue(vectorSource, "uuid", uuid);
}

export function removeFeaturesDescendedFromRoofPlaneUuid(vectorSource, roofPlaneUuid) {
  removeFeaturesMatchingKeyAndValue(vectorSource, "roofPlaneUuid", roofPlaneUuid);
}

export function removeFeaturesDescendedFromRoofSectionUuid(vectorSource, roofSectionUuid) {
  removeFeaturesMatchingKeyAndValue(vectorSource, "roofSectionUuid", roofSectionUuid);
}

function removeFeaturesMatchingKeyAndValue(vectorSource, key, value) {
  const features = vectorSource.getFeatures();

  features.forEach((feature) => {
    if (feature.get(key) === value) {
      vectorSource.removeFeature(feature);
    }
  });
}

export function ensureFeatureIsClockwise(feature) {
  const geometry = feature.getGeometry();
  // passing false to getCoordinates makes OpenLayers return coordinates where outer ring is clockwise
  const clockwiseCoordinates = geometry.getCoordinates(false);

  geometry.setCoordinates(clockwiseCoordinates);
}

// If the line is too short on the map (e.g. when you've zoomed out enough, polygon lines
// get very short and can disappear) the line is too short to show ornamental UI, like a
// dimension marker.
export function lineTooShortToShowOrnamentation(map, startPoint, endPoint, cutoffLineLength = 70) {
  const startPixel = map.getPixelFromCoordinate(startPoint);
  const endPixel = map.getPixelFromCoordinate(endPoint);

  const dx = startPixel[0] - endPixel[0];
  const dy = startPixel[1] - endPixel[1];
  const length = Math.sqrt(dx * dx + dy * dy);

  return length < cutoffLineLength;
}

export function buildLineStringSegmentFeaturesFromPolygonCoordinates(polygonCoordinates) {
  const segments = [];
  for (let i = 0; i < polygonCoordinates.length - 1; i++) {
    segments.push([polygonCoordinates[i], polygonCoordinates[i + 1]]);
  }

  const lineStringFeatures = segments.map((lineCoordinates, i) => {
    const geometry = new LineString(lineCoordinates);
    const feature = new Feature({ geometry });
    feature.set("lineNumber", i + 1);
    return feature;
  });

  return lineStringFeatures;
}

export function segmentCoordinatesFromPolygon(polygon) {
  const polygonCoordinates = polygon.getGeometry().getLinearRing(0).getCoordinates();
  const segmentCoordinates = [];
  for (let i = 0; i < polygonCoordinates.length - 1; i++) {
    segmentCoordinates.push([polygonCoordinates[i], polygonCoordinates[i + 1]]);
  }
  return segmentCoordinates;
}

export function relativePosition(endLonLat, startLonLat) {
  const signLng = Math.sign(endLonLat[0] - startLonLat[0]);
  const signLat = Math.sign(endLonLat[1] - startLonLat[1]);

  return [signLng, signLat];
}

export function distanceForPixels(map, pixelDistance) {
  const resolution = map.getView().getResolution(); // meters per pixel
  return pixelDistance * resolution;
}

export function findLineStringFeatureWithMatchingCoordinates(features, searchCoordinates) {
  function coordinatesAreEqual(coordinate1, coordinate2) {
    return (
      Math.abs(coordinate1[0] - coordinate2[0]) < PROXIMITY_CUTOFF &&
      Math.abs(coordinate1[1] - coordinate2[1]) < PROXIMITY_CUTOFF
    );
  }

  return features.find((f) => {
    const geometry = f.getGeometry();
    const featureCoordinates = geometry.getCoordinates();
    return (
      (coordinatesAreEqual(featureCoordinates[0], searchCoordinates[0]) &&
        coordinatesAreEqual(featureCoordinates[1], searchCoordinates[1])) ||
      (coordinatesAreEqual(featureCoordinates[0], searchCoordinates[1]) &&
        coordinatesAreEqual(featureCoordinates[1], searchCoordinates[0]))
    );
  });
}

export const SIDE_TO_COMPASS_DIRECTION_MAP = { top: "north", right: "east", bottom: "south", left: "west" };
export const COMPASS_DIRECTION_TO_SIDE_MAP = { north: "top", east: "right", south: "bottom", west: "left" };

// Note that coordinates are returned in clockwise order
export function rectangleFeatureSideCoordinates(feature, map) {
  const coordinates = feature.getGeometry().getLinearRing(0).getCoordinates();
  const lonLats = coordinates.map((coordinate) => toLonLat(coordinate));
  const pixels = coordinates.map((coordinate) => map.getPixelFromCoordinate(coordinate));
  const sides = {
    top: {
      coordinates: [coordinates[0], coordinates[1]],
      lonLats: [lonLats[0], lonLats[1]],
      pixels: [pixels[0], pixels[1]],
    },
    right: {
      coordinates: [coordinates[1], coordinates[2]],
      lonLats: [lonLats[1], lonLats[2]],
      pixels: [pixels[1], pixels[2]],
    },
    bottom: {
      coordinates: [coordinates[2], coordinates[3]],
      lonLats: [lonLats[2], lonLats[3]],
      pixels: [pixels[2], pixels[3]],
    },
    left: {
      coordinates: [coordinates[3], coordinates[4]],
      lonLats: [lonLats[3], lonLats[4]],
      pixels: [pixels[3], pixels[4]],
    },
  };
  return sides;
}

export function pixelsFromLatLng(map, latLng) {
  const lngLat = [latLng[1], latLng[0]];
  const coordinate = fromLonLat(lngLat);
  const pxCoordinate = map.getPixelFromCoordinate(coordinate);
  return pxCoordinate;
}

export function latLngObjToCoordinate(latLngObj) {
  const lngLat = [latLngObj.lng, latLngObj.lat];
  const coordinate = fromLonLat(lngLat);
  return coordinate;
}

export function coordinateToLatLngObj(coordinate) {
  const lngLat = toLonLat(coordinate);
  return { lng: lngLat[0], lat: lngLat[1] };
}

export function latLngToCoordinate(latLng) {
  const lngLat = [latLng[1], latLng[0]];
  const coordinate = fromLonLat(lngLat);
  return coordinate;
}

export const PIXEL_COORDINATE_COMPARISON_THRESHOLD = 0.00001;
export function truncatePixelCoordinateDecimal(num) {
  return truncateDecimal(num, PIXEL_COORDINATE_COMPARISON_THRESHOLD);
}

export function truncateDecimal(num, digits) {
  const numS = num.toString();
  const decPos = numS.indexOf(".");
  const substrLength = decPos == -1 ? numS.length : 1 + decPos + digits;
  const trimmedResult = numS.substr(0, substrLength);
  const finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

  return parseFloat(finalResult);
}

export function latLngFromPixelCoordinate(map, pixelCoordinate) {
  const coordinate = map.getCoordinateFromPixel(pixelCoordinate);
  const lngLat = toLonLat(coordinate);
  const latLng = [lngLat[1], lngLat[0]];
  return latLng;
}

export function distanceBetweenPixelCoordinatesInInches(map, pixelCoordinate1, pixelCoordinate2) {
  const coordinate1 = map.getCoordinateFromPixel(pixelCoordinate1);
  const coordinate2 = map.getCoordinateFromPixel(pixelCoordinate2);
  const lngLat1 = toLonLat(coordinate1);
  const lngLat2 = toLonLat(coordinate2);
  return getDistance(lngLat1, lngLat2) * METERS_TO_INCHES;
}
