import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Crop from "ol-ext/filter/Crop";

import BaseMapManager from "./base";

import { railsStyle, segmentsStyle } from "../styles/segments";
import { panelsStyle } from "../styles/zone-panels";
import { raftersStyle } from "../styles/rafters";

import { PANEL_DATA_TYPE, RAIL_DATA_TYPE } from "../../../da/map/data-types";
import { SELECT_TYPE_SEGMENTS } from "../../../da/layout-editor/helpers/toolbar-constants";
import { logger } from "../../../helpers/app";
import { reRenderSegment } from "../modification-helpers/segments/helpers";

import * as events from "../../../da/map/events";
export default class PanelProperties extends BaseMapManager {
  add() {
    this.buildRoofPlanesVectorSource();
    this.buildRaftersVectorSource();
    this.buildRoofSectionsVectorSource();
    this.buildSetbacksVectorSource();
    this.buildMeasuresVectorSource();
    this.buildPanelsVectorSource();
    this.buildSegmentsVectorSource();
    this.buildRailsVectorSource();
    this.buildThermalExpansionsVectorSource();
    this.buildAdjoinmentsVectorSource();
    this.buildContoursVectorSource();
    this.buildContourLegalityAdjoinmentsVectorSource();

    this.map = this.buildMap({
      layers: [
        this.tileLayer,
        this.raftersVectorLayer,
        this.visualMarkersVectorLayer,
        this.roofPlanesVectorLayer,
        this.measuresVectorLayer,
        this.panelsVectorLayer,
        this.roofSectionsVectorLayer,
        this.setbacksVectorLayer,
        this.segmentsVectorLayer,
        this.railsVectorLayer,
        this.thermalExpansionsVectorLayer,
        this.contourLegalityAdjoinmentsVectorLayer,
        this.adjoinmentsVectorLayer,
        this.contoursVectorLayer,
      ],
    });

    // We need to have the map in place first
    this.addRafterFeatures();
    this.renderContourFeatures();
    this.addBuildingImagePlacementLayers();

    this.setProjectSiteValuesOnMoveEnd = false;

    this.map.on("moveend", this.onMoveEnd);

    this.map.on(events.AFTER_MAP_FEATURES_RENDERING_EVENT, (event) => {
      logger(`Dispatch ${events.AFTER_MAP_FEATURES_RENDERING_EVENT} called from ${event.calledFrom}`);

      this.controller.focusedRoofPlane.roofSections.forEach((rs) => {
        rs.segments.forEach((s) => {
          if (s.hasContourCodesAndDetailsMismatch) {
            console.log("Warning: Contour Codes Mismatch");
            debugger;
          }
        });
      });

      this.refreshSegmentZonesPositionsAndContourCodes();

      this.controller.addSnapshotToUndoQueue();
    });
  }

  refreshSegmentZonesPositionsAndContourCodes() {
    this.controller.focusedRoofPlane.roofSections.forEach((roofSection) => {
      roofSection.segments.forEach((segment) => {
        segment.refreshZonesAndModulePositions();
        segment.refreshContourCodes();
      });
    });
  }

  clearFeaturesBeforeSnapshotUpdate() {
    this.clearPanelFeatures();
    this.clearSegmentFeatures();
    this.clearRailFeatures();
    this.clearAdjoinmentFeatures();
    this.clearContourLegalityAdjoinmentFeatures();
    this.clearContourFeatures();
  }

  renderFeaturesAfterSnapshotUpdate() {
    this.controller.focusedRoofPlane.roofSections.forEach((roofSection) => {
      roofSection.segments.forEach((segment) => {
        reRenderSegment(this.controller, segment);
      });
    });
    this.renderAdjoinAlignments();
    this.renderContourLegalityAdjoinAlignments();
    this.renderContourFeatures();
  }

  buildPanelsVectorSource() {
    this.panelsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.featuresForRoofPlanePanels(this.controller.focusedRoofPlane),
    });
  }

  get panelsVectorLayer() {
    if (!this.memoPanelsVectorLayer) {
      this.memoPanelsVectorLayer = new VectorLayer({
        source: this.panelsVectorSource,
        style: (feature) => panelsStyle(feature, this.map, this.controller.project.is716Or722),
        zIndex: 60,
      });
      this.memoPanelsVectorLayer.set("dataType", PANEL_DATA_TYPE);
    }
    return this.memoPanelsVectorLayer;
  }

  buildSegmentsVectorSource() {
    this.segmentsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.segmentFeatures,
    });
  }

  get segmentsVectorLayer() {
    if (!this.memoSegmentsVectorLayer) {
      this.memoSegmentsVectorLayer = new VectorLayer({
        source: this.segmentsVectorSource,
        style: (feature) => segmentsStyle(feature),
        zIndex: 40,
      });
    }
    return this.memoSegmentsVectorLayer;
  }

  buildRailsVectorSource() {
    this.railsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.railFeatures,
    });
  }

  get railsVectorLayer() {
    if (!this.memoRailsVectorLayer) {
      this.memoRailsVectorLayer = new VectorLayer({
        source: this.railsVectorSource,
        style: (feature) => railsStyle(feature),
        zIndex: 30,
      });
      this.memoRailsVectorLayer.set("dataType", RAIL_DATA_TYPE);
    }
    return this.memoRailsVectorLayer;
  }

  adjustPanelSegmentZIndexesForSelectType(selectType) {
    if (selectType === SELECT_TYPE_SEGMENTS) {
      this.segmentsVectorLayer.setZIndex(60);
      this.panelsVectorLayer.setZIndex(40);
    } else {
      this.panelsVectorLayer.setZIndex(60);
      this.segmentsVectorLayer.setZIndex(40);
    }
  }

  buildRaftersVectorSource() {
    this.raftersVectorSource = new VectorSource();
  }

  addRafterFeatures() {
    this.raftersVectorSource.addFeatures(this.mapModelSynchronizer.rafterFeatures());
    const roofPlaneFeature = this.roofPlanesVectorSource.getFeatures()[0];
    const cropFilter = new Crop({ feature: roofPlaneFeature, inner: false });
    this.raftersVectorLayer.addFilter(cropFilter);
  }

  get raftersVectorLayer() {
    if (!this.memoRaftersVectorLayer) {
      this.memoRaftersVectorLayer = new VectorLayer({
        source: this.raftersVectorSource,
        style: (feature) => raftersStyle(feature),
      });
    }
    return this.memoRaftersVectorLayer;
  }
}
