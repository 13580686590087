import DaRoofPlanes from "../../../../da/map/interaction-managers/select/roof-planes";
import { modifyRoofPlane, deleteRoofPlane } from "../../modification-helpers/roof-plane";
import {
  BUILDING_IMAGE_PLACEMENT_DATA_TYPE,
  MEASURE_DATA_TYPE,
  ROOF_PLANE_DATA_TYPE,
} from "../../../../da/map/data-types";
import { isPoint } from "../../../../da/map/ol-helpers";

export default class RoofPlanes extends DaRoofPlanes {
  constructor(controller) {
    super(controller);

    this.modifyRoofPlane = modifyRoofPlane;
    this.deleteRoofPlane = deleteRoofPlane;
  }

  filterSelects = (feature, layer) => {
    const layerDataType = layer && layer.get("dataType");
    const featureDataType = feature && feature.get("dataType");

    // If it's a point, this is a vertex selection and we're good
    if (feature && isPoint(feature)) return true;

    // If it's a building image placement, we don't want to select it.  The
    // transform interaction manager will handle that.
    if (featureDataType === BUILDING_IMAGE_PLACEMENT_DATA_TYPE) return false;
    if (layerDataType === BUILDING_IMAGE_PLACEMENT_DATA_TYPE) return false;

    const notALayer = layer === null;
    const roofPlaneLayer = layerDataType === ROOF_PLANE_DATA_TYPE;
    const measureLayer = layerDataType === MEASURE_DATA_TYPE;

    return notALayer || roofPlaneLayer || measureLayer;
  };
}
