import { fromLonLat } from "ol/proj";

import DaMapBaseController from "../../da/layout_editor/map_base_controller";
import MapManager from "../../../bx/map/map-manager";
import MapModelSynchronizer from "../../../bx/map/map-model-synchronizer";

import ProjectModel from "../../../bx/models/project-model";
import { logState } from "../../../bx/map/ol-helpers";

export default class MapBaseController extends DaMapBaseController {
  static targets = [
    "map",
    "compass",
    "drawBtn",
    "selectBtn",
    "measureBtn",
    "saveBtn",
    "panelStatusMenuBtn",
    "addPanelsBtn",
    "removePanelsBtn",
    "togglePanelsBtn",
    "paintPanelsBtn",
    "statusItemZoom",
    "statusItemRotation",
    "statusItemPanels",
    "statusItemPanelsContainer",
    "statusItemWattage",
    "statusItemWattageContainer",
    "editModeBtnGroup",
    "editorModeMenu",
    "undoBtn",
    "redoBtn",
  ];

  connect() {
    super.connect();

    this.project = ProjectModel.create(this.projectData);
    this.project.applyUpdatesReturnedBySave();
    window.IR_GLOBALS.projectModel = this.project;

    this.mapModelSynchronizer = new MapModelSynchronizer(this);

    this.mapManager = new MapManager(this);
    this.mapManager.add();
  }

  showGlobalOrigin() {
    this.mapManager.addVisualMarker(fromLonLat(this.project.globalOrigin.toLonLat));
  }

  get logShort() {
    return logState(this.mapModelSynchronizer, false);
  }

  get log() {
    return logState(this.mapModelSynchronizer, true);
  }
}
