import { toLonLat } from "ol/proj";

export default class BuildingImagePlacementCreator {
  constructor(mapManager, imagePath, uploadedBuildingImageId, width, height) {
    this.mapManager = mapManager;
    this.project = mapManager.project;

    this.imagePath = imagePath;
    this.uploadedBuildingImageId = uploadedBuildingImageId;
    this.width = width;
    this.height = height;
  }

  create() {
    let uploadedBuildingImage = this.project.getUploadedBuildingImage(this.uploadedBuildingImageId);

    if (!uploadedBuildingImage) {
      uploadedBuildingImage = this.project.addUploadedBuildingImage({
        id: this.uploadedBuildingImageId,
        imagePath: this.imagePath,
        width: this.width,
        height: this.height,
      });
    }

    const latLngPoints = this.#latLngPoints(this.width, this.height);
    this.placement = uploadedBuildingImage.addBuildingImagePlacement({ latLngPoints });

    return this.placement;
  }

  #latLngPoints(imageWidth, imageHeight) {
    const center = this.mapManager.mapCenter;
    const resolution = this.mapManager.mapResolution;

    const mapWidthUnits = imageWidth * resolution;
    const mapHeightUnits = imageHeight * resolution;

    // Place the center of the image at the center of the visible map
    const minX = center[0] - mapWidthUnits / 2;
    const minY = center[1] - mapHeightUnits / 2;
    const maxX = center[0] + mapWidthUnits / 2;
    const maxY = center[1] + mapHeightUnits / 2;

    const corners = [
      [minX, minY],
      [maxX, minY],
      [maxX, maxY],
      [minX, maxY],
    ];

    const rotatedCorners = this.#rotateCorners(corners);

    const latLngCorners = rotatedCorners.map((corner) => {
      const lonLat = toLonLat(corner);
      return { lat: lonLat[1], lng: lonLat[0] };
    });

    return latLngCorners;
  }

  #rotateCorners(corners) {
    const center = this.mapManager.mapCenter;
    const angle = this.mapManager.rotation;

    const cosAngle = Math.cos(angle);
    const sinAngle = Math.sin(angle);

    const centerX = center[0];
    const centerY = center[1];

    return corners.map(([x, y]) => {
      const dx = x - centerX;
      const dy = y - centerY;
      const xRot = dx * cosAngle - dy * sinAngle + centerX;
      const yRot = dx * sinAngle + dy * cosAngle + centerY;
      return [xRot, yRot];
    });
  }
}
