import { types } from "mobx-state-tree";

const CartesianModel = types
  .model("CartesianModel", {
    x: types.number,
    y: types.number,
  })
  .views((self) => ({
    get toArray() {
      return [self.x, self.y];
    },
    get toStringValue() {
      return JSON.stringify(this.toArray);
    },
    get toSimpleObject() {
      return {
        x: self.x,
        y: self.y,
      };
    },
    // If you are using a CartesianModel to represent a vector, these operations
    // are useful
    plus(other) {
      return CartesianModel.create({
        x: self.x + other.x,
        y: self.y + other.y,
      });
    },
    minus(other) {
      return CartesianModel.create({
        x: self.x - other.x,
        y: self.y - other.y,
      });
    },
    times(multiplier) {
      return CartesianModel.create({
        x: self.x * multiplier,
        y: self.y * multiplier,
      });
    },
    dividedBy(divisor) {
      return self.times(1.0 / divisor);
    },
    distanceTo(other) {
      const deltaX = other.x - self.x;
      const deltaY = other.y - self.y;

      return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    },
  }));

export default CartesianModel;
